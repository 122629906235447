import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../utils'
import { getNewsData, NewsPagesResponse } from '../api/cms'
import { ourColors } from '../components/Colors'
import { useStyletron } from 'baseui'
import { VimeoVideo } from '../components/VimeoVideo'
import { MainContent } from '../components/MainContent'
import { ErrorNotification } from '../components/Notification'
import { LinkButton } from '../components/LinkButton'

interface NewsPageHeaderProps {
  pageTitle: string
}
const NewsPageHeader = ({ pageTitle }: NewsPageHeaderProps) => {
  const [css] = useStyletron()
  return (
    <>
      <Grid align={ALIGNMENT.center}>
        <Cell span={[2.25, 3.5]}>
          <HeadingLevel>
            <Heading>{pageTitle}</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <hr className={css({ background: ourColors.interfaceGrey })} />
        </Cell>
      </Grid>
    </>
  )
}

interface NewsPageProps {
  location: WindowLocation
}
export const NewsPage = (props: NewsPageProps) => {
  const { token, setUserContext } = React.useContext(UserContext)
  const [pageTitle, setPageTitle] = React.useState('')
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const [mainText, setMainText] = React.useState('')
  const [video, setVideo] = React.useState('')
  const [buttonText, setButtonText] = React.useState('')
  const [buttonPagePath, setButtonPagePath] = React.useState('')
  const newsId = getQueryVariable('id', props.location.search)
  const [css] = useStyletron()

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!newsId) {
      navigate('/404', { replace: true })
    }

    if (token && newsId) {
      getNewsData(token, newsId)
        .then((response: NewsPagesResponse) => {
          if (response.meta.total_count > 0) {
            const content = response.items[0]
            if (content.title_visible) {
              setPageTitle(content.title)
            }
            setVideo(content.video)
            setMainText(content.main_text)
            if (
              content.link_button_text &&
              content.button_linked_page?.id &&
              content.button_linked_page?.meta?.type
            ) {
              setButtonText(content.link_button_text)
              if (content.button_linked_page.meta.type === 'checklists.ChecklistPlanPage') {
                setButtonPagePath(`/checklists/plan?planId=${content.button_linked_page.id}`)
              }
            }
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, newsId, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {pageTitle && <NewsPageHeader pageTitle={pageTitle} />}
      {video && <VimeoVideo videoUrl={video} />}
      {mainText && <MainContent richText={mainText} />}
      {buttonText && buttonPagePath && (
        <Grid>
          <Cell span={[4, 6]}>
            <div
              className={css({
                marginTop: '9px',
                marginBottom: '78px'
              })}
            >
              <LinkButton to={buttonPagePath}>{buttonText}</LinkButton>
            </div>
          </Cell>
        </Grid>
      )}
    </>
  )
}
interface LayoutNewsPageProps {
  location: WindowLocation
}
const LayoutNewsPage = (props: LayoutNewsPageProps) => {
  return (
    <Layout>
      <NewsPage location={props.location} />
    </Layout>
  )
}

export default LayoutNewsPage
